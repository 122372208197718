import React from 'react';

import Infocard from '../Cards/Infocard';

const Healthuser = () => {
  return (
    <div className="container mx-auto py-6">
      <div className="flex flex-col space-y-6 m-3">
        <Infocard
          image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-health-news-image-1.jpg"
          category="Health"
          categoryPath="/Health"
          title="The Top 11 Traits Health CEOs Have in Common"
          titlePath="/Common11health"
          date="June 28, 2021"
          description="Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet..."
          imgWidth="100%"
          imgHeight="200px" 
        />

        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
          <Infocard
            image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-health-news-image-2.jpg"
            category="Health"
            categoryPath="/Health"
            title="15 Counterintuitive Tips for Crushing Your Health Goals"
            titlePath="/Counterintuitivetips15"
            date="June 28, 2021"
            description="Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet..."
            imgWidth="100%"
            imgHeight="200px" 
          />

          <div className="hidden md:block">
            <Infocard
              image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-health-news-image-3.jpg"
              category="Health"
              categoryPath="/Health"
              title="The Most Beloved Health Products, According to Reviewers"
              titlePath="/Healthproduct"
              date="June 28, 2021"
              description="Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet..."
              imgWidth="100%"
              imgHeight="200px" 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Healthuser);
