import React, { useEffect } from 'react';

import Aiinovationcard from '../../Blogs/Blogcard/Techcard/Aiinovationcard';
import TechChangedlifecard from '../../Blogs/Blogcard/Techcard/TechChangeslifecard';
import TechFandamentalcard from '../../Blogs/Blogcard/Techcard/TechFandamentalcard';
import Sidebar from '../../Cards/Sidebar';
import CommingSoon from '../../Cards/Commingsoon';

const Technology  = () => {
  useEffect(() => {
    document.title='Technology  - PrimeContent'
  }, []);
  return (
    <div className="container pt-20 mx-auto px-4 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">

        {/* Left Section - Blog Section */}
    
        <div className="col-span-2">
        <h1 className="text-xl  font-semibold text-gray-800 pb-3 tracking-wide">
            TECHNOLOGY
          </h1>
          <hr/>
          <Aiinovationcard/>

          <TechFandamentalcard/>
          <TechChangedlifecard/>

        </div>

        {/* Right Section - Sidebar */}
     
         <div className="col-span-1">
            <Sidebar/>
         </div>

      </div>
    </div>
  );
};

export default Technology