import React, { useEffect } from 'react';

const Privacy = () => {
    useEffect(()=> {
        document.title='Privacy Policy - PrimeContent';
    }, []);

    return (
        <div className="min-h-screen pt-20 bg-gray-50 shadow-lg text-gray-800 py-20 px-6 lg:px-24">
            <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-lg p-10 lg:p-20">
                <h1 className="text-5xl font-bold mb-10 text-center border-b-2 border-gray-200 pb-5">Privacy Policy</h1>
                <p className="text-lg mb-6 leading-relaxed">
                    Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal data.
                </p>
                <div className="space-y-8">
                    <section className="border-l-4 border-blue-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">Information We Collect</h2>
                        <p className="text-base">
                            We gather information such as your name, email, IP address, and usage data when you interact with our website. Additionally, we may collect data through cookies and similar technologies.
                        </p>
                    </section>

                    <section className="border-l-4 border-blue-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">How We Use Your Information</h2>
                        <p className="text-base">
                            Your information helps us enhance your experience, communicate with you, and improve our services. We also use this data for personalized advertising, analytics, and to comply with legal obligations.
                        </p>
                    </section>

                    <section className="border-l-4 border-blue-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">Cookies and Tracking Technologies</h2>
                        <p className="text-base">
                            We use cookies and similar tracking technologies to track activity on our service and hold certain information. Cookies are files with a small amount of data that are stored on your device and help us enhance user experience and deliver personalized ads.
                        </p>
                    </section>

                    <section className="border-l-4 border-blue-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">Third-Party Advertising and AdSense</h2>
                        <p className="text-base">
                            We may display third-party advertisements on our website, which are served through Google AdSense. These ads may use cookies to track user behavior and interests, allowing for personalized ad experiences. You can opt-out of personalized ads through your Google account settings.
                        </p>
                    </section>

                    <section className="border-l-4 border-blue-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">Data Protection</h2>
                        <p className="text-base">
                            We use advanced security measures, including encryption and secure servers, to safeguard your data against unauthorized access and breaches. However, no method of transmission over the internet or method of electronic storage is 100% secure.
                        </p>
                    </section>

                    <section className="border-l-4 border-blue-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">Your Data Rights</h2>
                        <p className="text-base">
                            Depending on your location, you may have rights under data protection laws to access, rectify, or delete your personal data, and to object to or restrict certain processing of your data.
                        </p>
                    </section>

                    <section className="border-l-4 border-blue-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">Changes to This Privacy Policy</h2>
                        <p className="text-base">
                            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                        </p>
                    </section>
                </div>
                <p className="mt-10 text-center text-sm text-gray-500">
                    If you have any questions about our Privacy Policy, please contact us.
                </p>
            </div>
        </div>
    );
};

export default Privacy;
