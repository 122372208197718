import React, { useEffect } from 'react';

const CrunchTerms = () => {
    useEffect(()=> {
        document.title='Extra Crunch Terms  - PrimeContent'
   } , [])
    return (
        <div className="min-h-screen pt-20 bg-white text-gray-800 py-20 px-6 lg:px-24">
        <div className="max-w-4xl mx-auto bg-gray-50 shadow-xl rounded-lg p-10 lg:p-20">
            <h1 className="text-5xl font-bold mb-10 text-center border-b-2 border-gray-200 pb-5">Extra Crunch Terms</h1>
            <p className="text-lg mb-6 leading-relaxed">
                These terms govern your subscription to Extra Crunch. Please read carefully before subscribing.
            </p>
            <div className="space-y-8">
                <section className="border-l-4 border-purple-500 pl-4">
                    <h2 className="text-2xl font-semibold mb-3">Subscription Terms</h2>
                    <p className="text-base">
                        By subscribing, you agree to the terms and fees associated with the Extra Crunch service.
                    </p>
                </section>
                <section className="border-l-4 border-purple-500 pl-4">
                    <h2 className="text-2xl font-semibold mb-3">Content Access</h2>
                    <p className="text-base">
                        Subscribers gain exclusive access to premium content. Content is for personal use only and may not be shared.
                    </p>
                </section>
                <section className="border-l-4 border-purple-500 pl-4">
                    <h2 className="text-2xl font-semibold mb-3">Cancellation Policy</h2>
                    <p className="text-base">
                        Subscriptions can be canceled anytime, but refunds are not available for partial periods.
                    </p>
                </section>
            </div>
            <p className="mt-10 text-center text-sm text-gray-500">
                For questions about Extra Crunch Terms, please contact us.
            </p>
        </div>
    </div>
    );
};

export default CrunchTerms;
