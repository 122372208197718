import React, { useEffect } from "react";

const About = () => {
  useEffect(() => {
    document.title = "About - PrimeContent";
    // Meta tags for SEO
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Learn about our mission, meet the team, and discover what makes our blog special. Join us on our journey!";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = "about us, blog mission, team, blog journey, PrimeContent";
    document.head.appendChild(metaKeywords);

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaKeywords);
    };
  }, []);

  return (
    <div className="min-h-screen pt-20 bg-gray-50 text-gray-800 p-6 lg:p-12 shadow-[inset_0px_4px_10px_rgba(0,0,0,0.2)]">
      {/* Hero Section */}
      <section className="text-center py-16 text-black">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl md:text-5xl font-extrabold mb-6">About Us</h1>
          <p className="text-lg md:text-xl max-w-3xl mx-auto">
            Discover our mission, meet the team, and learn what makes our blog
            special. We're here to inspire and inform.
          </p>
        </div>
      </section>

      {/* Mission Statement Section */}
      <section className="max-w-4xl mx-auto text-center py-12">
        <h2 className="text-3xl font-semibold mb-4">Our Mission</h2>
        <p className="text-lg leading-relaxed">
          Our mission is to provide valuable, well-researched content that
          inspires, educates, and engages our audience. We believe in the power
          of storytelling and aim to create a platform where diverse voices can
          be heard.
        </p>
      </section>

      {/* Timeline Section */}
      <section className="py-12">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-semibold mb-8 text-center">
            Our Journey
          </h2>
          <div className="relative">
            {/* Timeline */}
            <div className="border-l-2 border-gray-300">
              {/* Timeline Item 1 */}
              <div className="relative mb-12 pl-6">
                <div className="absolute left-[-8px] w-4 h-4 bg-blue-600 rounded-full"></div>
                <div className="ml-8">
                  <h3 className="text-xl font-semibold">2024: The Beginning</h3>
                  <p className="text-gray-600 mt-2">
                    We launched our blog with the goal of sharing insightful and
                    engaging content. Our journey began with a small but
                    passionate team.
                  </p>
                </div>
              </div>
              {/* Timeline Item 2 */}
              <div className="relative mb-12 pl-6">
                <div className="absolute left-[-8px] w-4 h-4 bg-blue-600 rounded-full"></div>
                <div className="ml-8">
                  <h3 className="text-xl font-semibold">
                    2025: Growth and Expansion
                  </h3>
                  <p className="text-gray-600 mt-2">
                    I Want it to in Next Year We expanded our team and content offerings, reaching a wider
                    audience and establishing ourselves as a trusted source of
                    information.
                  </p>
                </div>
              </div>
              {/* Timeline Item 3 
              <div className="relative mb-12 pl-6">
                <div className="absolute left-[-8px] w-4 h-4 bg-blue-600 rounded-full"></div>
                <div className="ml-8">
                  <h3 className="text-xl font-semibold">
                    2021: Community Engagement
                  </h3>
                  <p className="text-gray-600 mt-2">
                    We focused on building a vibrant community through
                    interactive content and engagement with our readers.
                  </p>
                </div>
              </div>
              {/* Timeline Item 4 
              <div className="relative mb-12 pl-6">
                <div className="absolute left-[-8px] w-4 h-4 bg-blue-600 rounded-full"></div>
                <div className="ml-8">
                  <h3 className="text-xl font-semibold">2022: New Horizons</h3>
                  <p className="text-gray-600 mt-2">
                    We introduced new features and collaborations, aiming to
                    offer even more value and diverse perspectives.
                  </p>
                </div>
              </div>
              */}
            </div>
          </div>
        </div>
      </section>

      {/* Team Section 
      <section className="py-12 bg-gray-200">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-semibold mb-8">Meet Our Team</h2>
          <div className="flex flex-wrap justify-center gap-12">
             Team Member 1 
            <div className="w-72 bg-white p-6">
              <img
                className="w-32 h-32 rounded-full mx-auto mb-4"
                src="https://via.placeholder.com/150"
                alt="John Doe, Chief Editor"
                loading="lazy"
              />
              <h3 className="text-xl font-semibold mb-2">John Doe</h3>
              <p className="text-sm text-gray-500">Chief Editor</p>
              <p className="mt-2 text-gray-600">
                John is the mastermind behind our content strategy, ensuring
                every article is top-notch.
              </p>
            </div>

             Team Member 2 
            <div className="w-72 bg-white p-6">
              <img
                className="w-32 h-32 rounded-full mx-auto mb-4"
                src="https://via.placeholder.com/150"
                alt="Jane Smith, Lead Writer"
                loading="lazy"
              />
              <h3 className="text-xl font-semibold mb-2">Jane Smith</h3>
              <p className="text-sm text-gray-500">Lead Writer</p>
              <p className="mt-2 text-gray-600">
                Jane's passion for writing shines through in every post,
                captivating our readers with her words.
              </p>
            </div>

            Add more team members as needed 
          </div>
        </div>
      </section>
      */}

      {/* Testimonials Section */}
      <section className="max-w-4xl mx-auto py-12 text-center">
        <h2 className="text-3xl font-semibold mb-8">What Our Readers Say</h2>
        <div className="flex flex-wrap justify-center gap-12">
          <div className="w-80 bg-white p-6">
            <p className="text-lg text-gray-700">
              "This blog has completely changed the way I view the world. The
              insights are incredible!"
            </p>
            <p className="mt-4 text-sm text-gray-500">- IMRAN KHAN </p>
          </div>

          <div className="w-80 bg-white p-6">
            <p className="text-lg text-gray-700">
              "I look forward to every new post. The content is always engaging
              and thought-provoking."
            </p>
            <p className="mt-4 text-sm text-gray-500">- URFI JHOSI</p>
          </div>

          {/* Add more testimonials as needed */}
        </div>
      </section>
    </div>
  );
};

export default About;
