import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import useImageLoader from './UseImageLoader';

// Component to handle the header part of the BlogPost with animation
const BlogPostHeader = ({ image, title, imageLoaded }) => {
  const handleError = (event) => {
    event.target.src = '/path-to-fallback-image.jpg'; // Fallback image in case of an error
  };

  return (
    <header className="mb-8">
      <motion.div
        className="relative w-full h-64 md:h-80 lg:h-96"
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        {!imageLoaded && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-300">
            <div className="spinner border-t-4 border-b-4 border-blue-500 rounded-full w-10 h-10 animate-spin"></div>
          </div>
        )}
        {imageLoaded && (
          <img
            src={image}
            alt={title}
            className="w-full h-full object-cover"
            onError={handleError}
          />
        )}
      </motion.div>
      <motion.h1
        className="text-4xl font-bold mt-6 text-center text-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.6 }}
      >
        {title}
      </motion.h1>
    </header>
  );
};

BlogPostHeader.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  imageLoaded: PropTypes.bool.isRequired,
};

const BlogPost = ({ image, category, categoryPath, title }) => {
  const imageLoaded = useImageLoader(image);

  useEffect(() => {
    document.title = `${title} - PrimeContent`;
    window.scrollTo(0, 0);
  }, [title]);

  return (
    <>
      <Helmet>
        <title>{`${title} - PrimeContent`}</title>
        <meta name="description" content={`${title} - ${category}. Read more about ${category} on PrimeContent.`} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://yourwebsite.com${categoryPath}`} />
        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={`${title} - ${category}. Read more about ${category} on PrimeContent.`} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={`https://yourwebsite.com${categoryPath}`} />
        <meta property="og:type" content="article" />
        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={`${title} - ${category}. Read more about ${category} on PrimeContent.`} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <motion.article
        className="max-w-3xl mx-auto p-6 bg-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        <BlogPostHeader image={image} title={title} imageLoaded={imageLoaded} />
        <div className="text-sm text-gray-600 mb-8 flex justify-between items-center">
          <NavLink to={categoryPath} className="text-blue-600 hover:underline">
            {category}
          </NavLink>
          <a
            href="#comment-section"
            className="text-blue-600 hover:underline"
          >
            Comment
          </a>
        </div>
        {/* Add your content and comment section here */}
      </motion.article>
    </>
  );
};

BlogPost.propTypes = {
  image: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  categoryPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default React.memo(BlogPost);
