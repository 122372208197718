import React from 'react';
import { Helmet } from 'react-helmet';
import BlogCard from '../../../Cards/BlogCard';

import Failure from '../../../images/Inspiration/Failure.jpg';
const CommonFailurecard = () => {
  return (
    <div className="my-6 "> {/* Added spacing around the card */}
      <Helmet>
      <title>Why Success  Isn't for Everyone: Common Failures</title>
        <meta name="description" content="Explore common failures and understand why success isn't for everyone. Learn about the challenges and strategies for overcoming setbacks." />
        <meta property="og:title" content="Why Success Isn't for Everyone: Common Failures" />
        <meta property="og:description" content="Explore common failures and understand why success isn't for everyone. Learn about the challenges and strategies for overcoming setbacks." />
        <meta property="og:image" content={Failure} />
      </Helmet>
      <BlogCard 
        image={Failure}
        category="Inspiraion"
        categoryPath="/Inspiration"
        title="Why Success Isn't for Everyone : Common Failures"
        description="Failure is when you try to do something, but it doesn't go as planned. Imagine you set a goal but don't achieve it—you start feeling uncomfortable, and sometimes it leads to feelings of depression and anxiety, making it hard to stay motivated […]" 
        postPath="/Inspiration/CommonFailure"
      />
    </div>
  );    
};

export default React.memo(CommonFailurecard);
