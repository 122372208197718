import {
  useEffect,
  useState,
} from 'react';

const useImageLoader = (src) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true; // Track if the component is still mounted

    const img = new Image();
    img.src = src;
    img.onload = () => {
      if (isMounted) {
        setLoaded(true);
      }
    };
    img.onerror = () => {
      if (isMounted) {
        setLoaded(false); // You could handle this differently, e.g., show a fallback
      }
    };

    // Cleanup function to handle component unmounting
    return () => {
      isMounted = false;
    };
  }, [src]);

  return loaded;
};

export default useImageLoader;
