import React from 'react';

import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

const FooterNavbar = () => {
  const menuItems = [
    { name: 'Home', path: '/' },
    { name: 'StockMarket', path: '/Stockmarket' },
    { name: 'Technology', path: '/Technology' },
    { name: 'HackVerse' , path: '/HackVerse'},
    { name: 'Politics', path: '/Politics' },
    { name: 'Automobile', path: '/Automobile' },
    { name: 'Health', path: '/Health' },/*
    { name: 'Codebase' , path: '/Codebase'},*/
    { name: 'Inspiration', path: '/Inspiration' },
    { name: 'Interactive Sessions', path: '/Intrectivesessions' },
  ];

  return (
    <nav className="bg-white border-b border-gray-200 py-4">
      <div className="container mx-auto flex justify-center items-center lg:justify-between">
        {/* Logo */}
        <div className="text-2xl font-bold text-gray-800">
          <NavLink to="/" className="font-serif">
            PRIME-CONTENT
          </NavLink>
        </div>

        {/* Full Menu for Large Screens */}
        <ul className="hidden lg:flex justify-center flex-grow space-x-8">
          {menuItems.map((item) => (
            <li key={item.name}>
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  `text-gray-600 hover:text-blue-600 transition-colors duration-300 ${
                    isActive ? 'text-black font-semibold border-b-2 border-black' : ''
                  }`
                }
              >
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                  {item.name}
                </motion.div>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default FooterNavbar;
