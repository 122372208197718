// RedirectOnRefresh.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectOnRefresh() {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the navigation is due to a page reload (not normal navigation)
    if (performance.navigation.type === performance.navigation.TYPE_RELOAD && window.location.pathname !== '/') {
      // Redirect to the home page
      navigate('/');
    }
  }, [navigate]);

  window.scrollTo(0, 0);

  return null;
}

export default RedirectOnRefresh;
