import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Contact = () => {
    useEffect(() => {
        document.title = 'Contact - PrimeContent';
    }, []);

    return (
        <div className="min-h-screen pt-20 p-8 rounded-md shadow-[inset_0px_4px_10px_rgba(0,0,0,0.2)]">
            <Helmet>
                <meta name="description" content="Get in touch with PrimeContent. We're here to assist you with any inquiries or concerns you may have. Contact us via phone, email, or visit our location in Mandi Bahauddin, Pakistan." />
                <meta name="keywords" content="PrimeContent, Contact PrimeContent, Reach PrimeContent, PrimeContent support, Contact us" />
                <link rel="canonical" href="https://www.yourwebsite.com/contact" />
            </Helmet>
            
            <header className="max-w-5xl mx-auto text-center space-y-12 mb-16">
                <h1 className="text-3xl font-bold text-gray-700">Contact Us</h1>
                <p className="text-lg text-gray-600">
                    We’re here to assist you with any inquiries or concerns you may have.
                </p>
            </header>

            <section className="max-w-5xl mx-auto text-center space-y-12 mb-16">
                <h2 className="text-xl font-bold text-gray-700">Our Contact Information</h2>
                <div className="flex flex-col md:flex-row justify-between text-left space-y-8 md:space-y-0">
                    <div className="flex flex-col items-center md:items-start space-y-2">
                        <h3 className="text-lg font-bold text-gray-700">Address</h3>
                        <p className="text-gray-500">Local Market, Mandi Bahauddin, Pakistan</p>
                    </div>
                    <div className="flex flex-col items-center md:items-start space-y-2">
                        <h3 className="text-lg font-bold text-gray-700">Phone</h3>
                        <p className="text-gray-500">+92 319-3591639</p>
                    </div>
                    <div className="flex flex-col items-center md:items-start space-y-2">
                        <h3 className="text-lg font-bold text-gray-700">Email</h3>
                        <p className="text-gray-500">PrimeContent@gmail.com</p>
                    </div>
                </div>
            </section>

            <section className="max-w-4xl mx-auto bg-white p-10 rounded-lg shadow-lg mb-16">
                <h2 className="text-3xl font-bold mb-8 text-center text-gray-700">Get In Touch</h2>
                <form className="space-y-8">
                    <div className="flex flex-col md:flex-row md:space-x-6">
                        <div className="flex flex-col w-full md:w-1/2">
                            <label htmlFor="name" className="text-sm font-semibold text-gray-600">Full Name</label>
                            <input 
                                type="text" 
                                id="name" 
                                className="mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Enter your name"
                            />
                        </div>
                        <div className="flex flex-col w-full md:w-1/2 mt-6 md:mt-0">
                            <label htmlFor="email" className="text-sm font-semibold text-gray-600">Email Address</label>
                            <input 
                                type="email" 
                                id="email" 
                                className="mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Enter your email"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="subject" className="text-sm font-semibold text-gray-600">Subject</label>
                        <input 
                            type="text" 
                            id="subject" 
                            className="mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter the subject"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="message" className="text-sm font-semibold text-gray-600">Message</label>
                        <textarea 
                            id="message" 
                            rows="6"
                            className="mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter your message"
                        ></textarea>
                    </div>
                    <button 
                        type="submit" 
                        className="w-full py-3 bg-blue-600 text-white rounded-md font-semibold hover:bg-blue-700 transition duration-300"
                    >
                        Send Message
                    </button>
                </form>
            </section>

            <section className="max-w-5xl mx-auto">
                <div className="w-full h-72 md:h-96 rounded-lg overflow-hidden shadow-lg">
                    <iframe 
                        title="Google Map" 
                        className="w-full h-full border-0"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345098377!2d144.95373531550465!3d-37.81627974202195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577bedd712e13e4!2sEnvato!5e0!3m2!1sen!2sau!4v1617171982340!5m2!1sen!2sau" 
                        allowFullScreen="" 
                        loading="lazy"
                    ></iframe>
                </div>
            </section>
        </div>
    )
};

export default Contact;
