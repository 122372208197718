import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const ProductCard = ({
  backgroundImage,
  category,
  categoryPath,
  title,
  date,
  postPath,
  width = '100%',  // Default width
  height = 'auto', // Default height
  imageWidth = '100%',
  imageHeight = '12rem', // Default image height
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleError = (e) => {
    e.target.src = '/path-to-fallback-image.jpg'; // Fallback image on error
    setImageLoaded(true);
  };

  useEffect(() => {
    const img = new Image();
    img.src = backgroundImage;
    img.onload = handleImageLoad;
    img.onerror = handleError;
  }, [backgroundImage]);

  return (
    <div
      className="rounded-lg shadow-md overflow-hidden bg-white"
      style={{
        width: width,   // Apply the custom width
        height: height, // Apply the custom height
      }}
    >
      {/* Clickable Image */}
      <NavLink to={postPath} className="block relative" aria-label={title}>
        {!imageLoaded && (
          <div className="loader" style={{ height: imageHeight }}>Loading...</div>
        )}
        {imageLoaded && (
          <img
            src={backgroundImage}
            alt={title}
            className="object-cover w-full h-full"
            style={{
              width: imageWidth,   // Image width applied directly to img tag
              height: imageHeight, // Image height applied directly to img tag
            }}
            loading="lazy" // Lazy loading for better performance
            onError={handleError} // Handle image loading errors
          />
        )}
      </NavLink>

      <div className="p-4">
        {/* Clickable Category Box */}
        {category && (
          <NavLink
            to={categoryPath}
            className="inline-block bg-blue-600 text-white px-3 py-1 rounded-md text-sm mb-2"
            aria-label={`Category: ${category}`}
          >
            {category}
          </NavLink>
        )}

        {/* Clickable Title */}
        <NavLink to={postPath} className="block text-black">
          <h2 className="text-2xl font-bold transition-colors duration-200 hover:text-gray-700">
            {title}
          </h2>
        </NavLink>

        {/* Date */}
        {date && <p className="text-gray-600 text-sm mt-2">{date}</p>}
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  categoryPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  postPath: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
};

export default React.memo(ProductCard);
