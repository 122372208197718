import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const FeatureCard = ({
  image,
  category,
  categoryPath,
  title,
  titlePath,
  date,
  imgAlt = "Blog Image",
  imgWidth = '100%',
  imgHeight = 'auto'
}) => {
  return (
    <div className="relative shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
      {/* Background Image */}
      <NavLink to={titlePath} className="block" aria-label={title}>
        <img
          src={image}
          alt={imgAlt}
          style={{
            width: imgWidth,
            height: imgHeight,
          }}
          className="w-full h-64 object-cover transform hover:scale-105 transition-transform duration-300"
          loading="lazy"
        />
      </NavLink>

      {/* Content Overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>

      <div className="absolute bottom-4 left-4 z-10 text-white">
        {/* Category */}
        <NavLink 
          to={categoryPath} 
          className="text-xs uppercase bg-blue-600 px-2 py-1 rounded transition-colors hover:bg-blue-500">
          {category}
        </NavLink>

        {/* Title */}
        <h2 className="text-xl font-semibold mt-2 leading-tight">
          <NavLink to={titlePath} className="hover:text-gray-200 transition-colors">
            {title}
          </NavLink>
        </h2>

        {/* Date */}
        <p className="text-sm mt-1 text-gray-300">{date}</p>
      </div>
    </div>
  );
};

FeatureCard.propTypes = {
  image: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  categoryPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titlePath: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  imgAlt: PropTypes.string,
  imgWidth: PropTypes.string,
  imgHeight: PropTypes.string,
};

export default React.memo(FeatureCard);
 