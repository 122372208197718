import React from 'react';

import { Outlet } from 'react-router-dom';

import Footer from './Components/Footer/Footer';
import FooterNavbar from './Components/Footer/FooterNavbar';
import Navcom from './Components/Header/Navcom';
import RedirectOnRefresh from './Components/Home/RedirectOnRefresh';

const Layout = () => {
    return(
        <div className='flex  justify-center min-h-screen'>
            <div className='w-full max-w-screen-xl'>
                <Navcom />
                    <RedirectOnRefresh/>
                    <Outlet/>
                  <FooterNavbar/>
                 <Footer/>
            </div>
        </div>
    )
}
export default Layout ;