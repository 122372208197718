import React from 'react';

import BlogCard from '../../../Cards/BlogCard';

const Govtchangecard = () => {
  return (
    <div className="my-6"> {/* Added spacing around the card */}
      <BlogCard 
        image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-editor-pick-img-3.jpg"
        category="Politics"
        categoryPath="/Politics"
        title="The Frightening Affect of Climate Change on Government"
        description="Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet volutpat rutrum sociis quis velit, commodo enim aliquet. Nunc volutpat tortor libero at augue mattis neque, suspendisse aenean praesent sit habitant laoreet felis lorem nibh diam faucibus viverra penatibus donec etiam sem consectetur vestibulum purus […]" 
        postPath="/Govtchange"
      />
    </div>
  );
};

export default React.memo(Govtchangecard);
