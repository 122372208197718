import React from 'react';
import { Helmet } from 'react-helmet';
import BlogCard from '../../../Cards/BlogCard';

import Getting_Started from '../../../images/HackVerse/Getting_Started.jpg';
const GettingStartedHackingCard = () => {
  return (
    <div className="my-6 "> {/* Added spacing around the card */}

      <Helmet>
      <title>Getting Started with Ethical Hacking: A Beginner's Guide</title>
        <meta name="description" content="Getting Started with Ethical Hacking: A Beginner's Guide" />
        <meta property="og:title" content="Getting Started with Ethical Hacking: A Beginner's Guide" />
        <meta property="og:description" content="Getting Started with Ethical Hacking: A Beginner's Guide" />
        <meta property="og:image" content={Getting_Started} />
      </Helmet>

      <BlogCard 
        image={Getting_Started}
        category="HackVerse"
        categoryPath="/HackVerse"
        title="Getting Started with Ethical Hacking: A Beginner's Guide"
        description=" A Ethical Hacking is also known as  a penetration tester or a white hat hacker  Place where we find Weakness or vulnerabilities in a computer systems, networks, or web applications  by using Some Tools and Trick and from your Knowledge and fix it before attack from Black Hat hacker or malicious hackers but also white hat hacker do with permission of organisation. […]" 
        postPath="/HacKVerse/Getting_Started_with_Ethical_Hacking"
      />
    </div>
  );    
};

export default React.memo(GettingStartedHackingCard);
