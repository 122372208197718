import React from 'react';

import BlogCard from '../../../Cards/BlogCard';

const StockEffectcard = () => {
  return (
    <div className="my-6"> {/* Added spacing around the card */}
      <BlogCard 
        image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-editor-pick-img-1.jpg"
        category="Stock Market"
        categoryPath="/Stockmarket"
        title="Why Stock Market Affects Men and Women Differently"
        description="Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet volutpat rutrum sociis quis velit, commodo enim aliquet. Nunc volutpat tortor libero at augue mattis neque, suspendisse aenean praesent sit habitant laoreet felis lorem nibh diam faucibus viverra penatibus donec etiam sem consectetur vestibulum purus […]" 
        postPath="/Marketeffect"
      />
    </div>
  );
};

export default React.memo(StockEffectcard);
