import React from 'react';

import BlogCard from '../../../Cards/BlogCard';

const Top7answersAutomobilecard = () => {
  return (
    <div className="my-6"> {/* Added spacing around the card */}
      <BlogCard 
        image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-automobile-news-image-2.jpg"
        category="Automobile"
        categoryPath="/Automobile"
        title="7 Answers to the Most Frequently Asked Questions About Automobile"
        description="Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet volutpat rutrum sociis quis velit, commodo enim aliquet. Nunc volutpat tortor libero at augue mattis neque, suspendisse aenean praesent sit habitant laoreet felis lorem nibh diam faucibus viverra penatibus donec etiam sem consectetur vestibulum purus […]" 
        postPath="/Top7answerAutomobile"
      />
    </div>
  );
};

export default React.memo(Top7answersAutomobilecard);
