import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import useImageLoader from './UseImageLoader';

const ImageCard = ({
  backgroundImage,
  category,
  categoryPath,
  title,
  date,
  postPath,
  width = '100%',  // Default width
  height = '16rem', // Default height
}) => {
  const imageLoaded = useImageLoader(backgroundImage);

  return (
    <div
      className="relative rounded-lg shadow-md overflow-hidden group"
      style={{
        width: width,   // Apply the custom width
        height: height, // Apply the custom height
      }}
    >
      {/* Loader */}
      {!imageLoaded && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
          <div className="loader"></div> {/* Replace with your actual loader */}
        </div>
      )}

      {/* Background Image and Overlay */}
      {imageLoaded && (
        <div
          className="w-full h-full bg-cover bg-center transition-transform duration-500 ease-in-out group-hover:scale-105"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-15"></div>
        </div>
      )}

      {/* Overlay Link to Blog Post */}
      <NavLink to={postPath} className="absolute inset-0 z-10" aria-label={title}>
        {/* Empty NavLink to cover the entire card */}
      </NavLink>

      {/* Category Box */}
      <NavLink
        to={categoryPath}
        className="absolute top-4 left-4 bg-blue-600 text-white px-3 py-1 rounded-md text-sm z-20 hover:bg-blue-700 transition-colors duration-200"
        onClick={(e) => e.stopPropagation()} // Prevents the click from bubbling up to the parent NavLink
        aria-label={`Category: ${category}`}
      >
        {category}  
      </NavLink>

      {/* Title and Date */}
      <div className="absolute bottom-4 left-4 right-4 z-20">
        <h2 className="text-2xl font-bold text-white mb-2">{title}</h2>
        <p className="text-sm text-gray-300">{date}</p>
      </div>
    </div>
  );
};

ImageCard.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  categoryPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  postPath: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default React.memo(ImageCard);
