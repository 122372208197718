import React from 'react';

import BlogCard from '../../../Cards/BlogCard';

const Common11healthcard = () => {
  return (
    <div className="my-6">
      <BlogCard 
        image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-health-news-image-1.jpg"
        category="Health"  
        categoryPath="/Health"
        title="The Top 11 Traits Health CEOs Have in Common"  
        description="Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet volutpat rutrum sociis quis velit, commodo enim aliquet. Nunc volutpat tortor libero at augue mattis neque, suspendisse aenean praesent sit habitant laoreet felis lorem nibh diam faucibus viverra penatibus donec etiam sem consectetur vestibulum purus […]" 
        postPath="/Common11health"
      />
    </div>
  );
};

export default React.memo(Common11healthcard);
