import React from 'react';

import { NavLink } from 'react-router-dom';

const NextAuthor = () => {
  return (
    <div className="bg-white m-6 p-6 md:p-8 lg:p-10 rounded-md  flex flex-col md:flex-row justify-between items-center">
      {/* Icon and Text */}
      <div className="flex items-center mb-4 md:mb-0">
        <div className="text-4xl text-gray-600 mr-4">
          <i className="fas fa-newspaper" aria-hidden="true"></i>
        </div>
        <div>
          <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-800">
            Your posts will be the next
          </h2>
          <p className="text-sm md:text-base lg:text-lg text-gray-500">
            If you Want to write post on This site then click on button
          </p>
        </div>
      </div>

      {/* Responsive Button */}
      <div className="w-full md:w-auto">
        <NavLink
          to="/Contact"
          className="block w-full md:w-auto text-center px-3 py-2 md:px-6 md:py-3 lg:px-8 lg:py-4 border border-gray-800 text-black text-sm md:text-base lg:text-lg text-gray-800 rounded hover:bg-gray-100 transition-colors duration-300 ease-in-out"
          aria-label="Be a Guest Author"
        >
          Be A Guest Author
        </NavLink>
      </div>
    </div>
  );
};

export default React.memo(NextAuthor);
