import React from 'react';

import BlogCard from '../../../Cards/BlogCard';

const Healthindustrycard = () => {
  return (
    <div className="my-6"> {/* Added spacing around the card */}
      <BlogCard 
        image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-latest-news-image-3.jpg"
        category="Health"
        categoryPath="/Health"
        title="The Health Industry Is Changing Fast. Here’s How to Keep Pace"
        description="Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet volutpat rutrum sociis quis velit, commodo enim aliquet. Nunc volutpat tortor libero at augue mattis neque, suspendisse aenean praesent sit habitant laoreet felis lorem nibh diam faucibus viverra penatibus donec etiam sem consectetur vestibulum purus […]" 
        postPath="/Healtindustry"
      />
    </div>
  );
};

export default React.memo(Healthindustrycard);
