import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const Infocard = ({
  image,
  category,
  categoryPath,
  title,
  titlePath,
  date,
  description,
  isMustRead = false,    // New prop to indicate if the card is a "Must Read"
  imgAlt = "Blog Image",  // Default alt text
  imgWidth = '100%',      // Default width
  imgHeight = 'auto'      // Default height
}) => {
  const handleError = (e) => {
    e.target.src = '/path-to-fallback-image.jpg'; // Fallback image on error
  };

  return (
    <div className={`flex flex-col border ${isMustRead ? 'border-red-500 bg-red-50' : 'border-gray-300 bg-white'} p-4 rounded-lg shadow-lg`}>
      
     

      {/* Image */}
      <NavLink to={titlePath} aria-label={title}>
        <img
          src={image}
          alt={imgAlt}
          style={{
            width: imgWidth,
            height: imgHeight,
          }}
          className="object-cover rounded-t-lg"
          loading="lazy" // Lazy loading for better performance
          onError={handleError} // Handle image loading errors
        />
      </NavLink>
      
      <div className="mt-4">
        {/* Category */}
        {category && (
          <NavLink to={categoryPath} className="text-blue-600 text-sm hover:text-blue-800" aria-label={`Category: ${category}`}>
            {category}
          </NavLink>
        )}
        
        {/* Title */}
        {title && (
          <h2 className="text-2xl font-bold mt-2">
            <NavLink to={titlePath} className="text-gray-600 hover:text-gray-800">
              {title}
            </NavLink>
          </h2>
        )}

        {/* Date */}
        {date && <p className="text-gray-500 text-sm mt-1">{date}</p>}

        {/* Description */}
        {description && <p className="text-gray-700 mt-2">{description}</p>}
      </div>
    </div>
  );
};

Infocard.propTypes = {
  image: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  categoryPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titlePath: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isMustRead: PropTypes.bool,       // New prop for "Must Read"
  imgAlt: PropTypes.string,
  imgWidth: PropTypes.string,
  imgHeight: PropTypes.string,
};

export default React.memo(Infocard);
