import React from 'react';

import PropTypes from 'prop-types';
import {
  FaCalendarAlt,
  FaUserAlt,
} from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const SessionCard = ({ image, title, description, date, host, speaker }) => {
  return (
    <div className=" p-4 rounded-lg">
      <img src={image} alt={title} className="w-full h-FULL  object-cover mb-4 rounded-lg" />
      <h2 className="text-2xl font-bold mb-2">{title}</h2>
      <p className="text-gray-700 mb-4">{description}</p>
      <div className="flex justify-between items-center text-sm text-blue-700">
        <div className="flex items-center">
          <FaCalendarAlt className="mr-2" />
          <span>{date}</span>
        </div>
        <div className="flex items-center">
          <FaUserAlt className="mr-2" />
          <span>Hosted by {host}</span>
        </div>
        <div className="flex items-center">
          <FaUserAlt className="mr-2" />
          <span>Speaker {speaker}</span>
        </div>
      </div>
        {/* Add the button here */}
        <NavLink to={'/Contact'} >
        <div className="text-center mt-8  flex ">
            <button className="px-6 py-2 border border-gray-500 text-gray-700 font-medium transition-colors duration-300 hover:bg-black hover:text-white">
                  Register Now
             </button>
        </div>
        </NavLink>
      </div>
  );
};
SessionCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  host: PropTypes.string,
  speaker: PropTypes.string,
};

SessionCard.defaultProps = {
  host: 'TBA', // Default value for host if not provided
  speaker: 'TBA', // Default value for speaker if not provided
};
export default SessionCard;
