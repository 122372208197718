import React from 'react';

import BlogPost from '../../../Cards/BlogpostCard';

const Stockeffectpost = () => {

    const paragraphs = [
        "Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet volutpat rutrum sociis quis velit, commodo enim aliquet.",
        "Nunc volutpat tortor libero at augue mattis neque, suspendisse aenean praesent sit habitant laoreet felis lorem nibh diam faucibus viverra penatibus donec etiam sem consectetur vestibulum purus non arcu suspendisse ac nibh tortor, eget elementum lacus, libero sem viverra elementum."
      ];
    
      const sections = [
        {
          heading: "Cursus iaculis etiam in",
          text: "In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet volutpat rutrum sociis quis velit, commodo enim aliquet."
        },
        {
          heading: "Magna enim, convallis ornare",
          text: "Sollicitudin bibendum nam turpis non cursus eget euismod egestas sem nunc amet, tellus at duis."
        }
      ];

    return (
        <BlogPost
          image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-editor-pick-img-1.jpg"
          category="Stock Market"
          categoryPath="/Stockmarket"
          title="Why Stock Market Affects Men and Women Differently"
          paragraphs={paragraphs}
          sections={sections}
        />
      );
}

export default Stockeffectpost;