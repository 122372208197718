import React, { useEffect } from 'react';

import SessionCard from '../../Cards/SessionCard';

const InteractiveSessions = () => {
  useEffect(() => {
    document.title = 'Interactive Sessions - PRIMECONTENT';
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="text-center pt-20 my-8">
        <h1 className="text-5xl font-bold">Interactive Sessions</h1>
        <p className="text-lg text-gray-700 mt-2">
          These Session are also comming soon in some Week .
        </p>
        <hr className="mt-4 w-1/2 mx-auto border-gray-400" />
      </div>

      {/* Session Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
        <SessionCard
          image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-upcoming-session-cover-img.jpg"
          title="Transform 2024"
          description="Welcome to social media in SEP 2024, where trends change faster than Taylor Swift fan theories. There are A hotest new platforms, fresh ways to shop, and countless algorithm updates. It’s enough to make even the savviest heads spin."
          date="14 – 16 Sep"
          host="Steven Green"
          speaker="Thomas Martin"
        />
        <SessionCard
          image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-upcoming-session-cover-img-2.jpg"
          title="Cryptocurrency"
          description="Bitcoin is showing signs of weakening demand as September, a month often associated with declining prices, approaches."
          date="10 – 13 Sep"
          host="Gary O’neil"
          speaker="Emily Dunn"
        />
      </div>
    </div>
  );
};

export default React.memo(InteractiveSessions);
