import React, { useEffect } from 'react';

const Terms = () => {
    useEffect(()=> {
        document.title='Terms of Service  - PrimeContent'
   } , [])
    return (
<div className="min-h-screen pt-20 bg-gray-50 text-gray-800 py-20 px-6 lg:px-24">
            <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-lg p-10 lg:p-20">
                <h1 className="text-5xl font-bold mb-10 text-center border-b-2 border-gray-200 pb-5">Terms of Service</h1>
                <p className="text-lg mb-6 leading-relaxed">
                    By accessing our services, you agree to these terms. Please read them carefully.
                </p>
                <div className="space-y-8">
                    <section className="border-l-4 border-green-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">Acceptance of Terms</h2>
                        <p className="text-base">
                            Your use of our services signifies your acceptance of these terms and compliance with applicable laws.
                        </p>
                    </section>
                    <section className="border-l-4 border-green-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">User Responsibilities</h2>
                        <p className="text-base">
                            Users must act responsibly and refrain from activities that harm others or our platform.
                        </p>
                    </section>
                    <section className="border-l-4 border-green-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">Changes to Terms</h2>
                        <p className="text-base">
                            We reserve the right to update these terms. Continued use of our services indicates acceptance of any changes.
                        </p>
                    </section>
                </div>
                <p className="mt-10 text-center text-sm text-gray-500">
                    For any questions regarding these Terms of Service, please contact us.
                </p>
            </div>
        </div>
    );
};

export default Terms;
