import React from 'react';

import { NavLink } from 'react-router-dom';

const Box = ({ category, categoryPath, title, postPath }) => {
  return (
    <div className="p-4 w-full lg:w-1/3">
      <div className="border-b-2 pb-4">
        {/* Category */}
        <NavLink
          to={categoryPath}
          className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
          aria-label={`View posts in category: ${category}`}
        >
          {category}
        </NavLink>

        {/* Title */}
        <h2 className="text-xl font-semibold text-gray-800 mt-2">
          <NavLink
            to={postPath}
            className="hover:text-gray-600 transition-colors duration-300"
            aria-label={`Read more: ${title}`}
          >
            {title}
          </NavLink>
        </h2>
      </div>
    </div>
  );
};

export default React.memo(Box);
