import React from 'react';

import PropTypes from 'prop-types';

import BlogPost from '../../../Cards/BlogpostCard';

// Define prop types for BlogPost
const BlogPostPropTypes = {
  image: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  categoryPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired
};

// Memoized functional component for Counterintuitivepost
const Counterintuitivepost = React.memo(() => {
  const paragraphs = [
    "Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet volutpat rutrum sociis quis velit, commodo enim aliquet.",
    "Nunc volutpat tortor libero at augue mattis neque, suspendisse aenean praesent sit habitant laoreet felis lorem nibh diam faucibus viverra penatibus donec etiam sem consectetur vestibulum purus non arcu suspendisse ac nibh tortor, eget elementum lacus, libero sem viverra elementum."
  ];

  const sections = [
    {
      heading: "Cursus iaculis etiam in",
      text: "In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet volutpat rutrum sociis quis velit, commodo enim aliquet."
    },
    {
      heading: "Magna enim, convallis ornare",
      text: "Sollicitudin bibendum nam turpis non cursus eget euismod egestas sem nunc amet, tellus at duis."
    }
  ];

  return (
    <BlogPost
      image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-health-news-image-1.jpg"
      category="Health"
      categoryPath="/Health"
      title="15 Counterintuitive Tips for Crushing Your Health Goals"
      paragraphs={paragraphs}
      sections={sections}
    />
  );
});

// Define prop types
Counterintuitivepost.propTypes = BlogPostPropTypes;

export default Counterintuitivepost;
