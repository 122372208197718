import React from 'react';

import FeatureCard from '../Cards/FeatureCard';

const FeaturedBlog = () => {
  const blogData = [
    {
      image: 'https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-latest-technology-news-image-1.jpg',
      category: 'Technology',
      categoryPath: '/Technology',
      title: 'This Will Fundamentally Change the Way You Look at Technology',
      titlePath: '/TechFandamental',
      date: 'June 28, 2021',
    },
    {
      image: 'https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-latest-technology-news-image-2.jpg',
      category: 'Technology',
      categoryPath: '/Technology',
      title: 'Technology Changed My Life. Here’s My Story',
      titlePath: '/TechChangedlife',
      date: 'June 28, 2021',
    },
  ];

  return (
    <section className="container mx-auto py-8 px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {blogData.map((blog) => (
          <FeatureCard
            key={blog.titlePath} // Use a unique key based on titlePath
            image={blog.image}
            category={blog.category}
            categoryPath={blog.categoryPath}
            title={blog.title}
            titlePath={blog.titlePath}
            date={blog.date}
            imgWidth="100%"
            imgHeight="250px" // Adjust as needed
          />
        ))}
      </div>
    </section>
  );
};

export default React.memo(FeaturedBlog);
