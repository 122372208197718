import React from 'react';
import Inovation from '../../../images/Tech/Inovation.jpg';
import BlogCard from '../../../Cards/BlogCard';

const Aiinovationcard = () => {
  return (  
    <div className="my-6"> {/* Added spacing around the card */}
      <BlogCard 
        image={Inovation}
        category="Technology"
        categoryPath="/Technology"
        title="AI Innovations: Transforming Every Aspect of Our World"
        description="Artificial Intelligence (AI) has become a big part of our daily lives, changing how we work, learn, and interact with the world. It's making everything smarter and more efficient. One of the most obvious places we see AI is in social [...]" 
        postPath="/Technology/Aiinovation"
      />
    </div>
  );
};

export default React.memo(Aiinovationcard);
