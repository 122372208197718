import React, { useEffect } from 'react';

 import Common11healtcard
   from '../../Blogs/Blogcard/Healthcard/Common11healthcard';
 import Counterintuitivecard
   from '../../Blogs/Blogcard/Healthcard/Counterintuitivetips15card';
 import Healthindustrycard
   from '../../Blogs/Blogcard/Healthcard/Healthindustrycard';
 import Healthproductcard
   from '../../Blogs/Blogcard/Healthcard/Healthproductcard';
import Sidebar from '../../Cards/Sidebar';
import CommingSoon from '../../Cards/Commingsoon';

const Healt  = () => {
  useEffect(() => {
    document.title='Automobile  - PrimeContent'
  }, []);
  return (
    <div className="container pt-20 mx-auto px-4 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">

        {/* Left Section - Blog Section */}
    
        <div className="col-span-2">
        <h1 className="text-xl  font-semibold text-gray-800 pb-3 tracking-wide">
            HEALTH
          </h1>
          <hr/>
         <Healthindustrycard/>
          <Common11healtcard/>
          <Counterintuitivecard/>
          <Healthproductcard/>

            
        </div>

        {/* Right Section - Sidebar */}
     
         <div className="col-span-1">
            <Sidebar/>
         </div>

      </div>
    </div>
  );
};

export default Healt;