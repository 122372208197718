import React from 'react';

import Box from './Box';

const Highlights = () => {
  const cardsData = [
    { category: 'Stock Market', categoryPath: '/Stockmarket', title: 'The Ultimate Guide to Stock Market', postPath: '/stock-market/guide' },
    { category: 'Politics', categoryPath: '/Politics', title: 'Don’t Share This Politics Insider Secret', postPath: '/politics/insider-secret' },
    { category: 'Technology', categoryPath: '/Technology', title: 'All Your Burning Technology Questions, Answered', postPath: '/technology/questions' },
    { category: 'Stock Market', categoryPath: '/Satockmarket', title: '15 Unbelievable Things You Never Knew About Stock Market', postPath: '/stock-market/unbelievable-things' },
    { category: 'Automobile', categoryPath: '/Automobile', title: 'Automobile: All the Stats, Facts, and Data You’ll Ever Need to Know', postPath: '/automobile/stats-facts' },
    { category: 'Politics', categoryPath: '/Politics', title: 'The Frightening Affect of Climate Change on Government', postPath: '/politics/climate-change' },
  ];

  return (
    <section className="container mx-auto py-8 px-4">
      <div className="flex flex-wrap -mx-4">
        {cardsData.map((card) => (
          <Box
            key={card.postPath} // Using postPath as a unique key
            category={card.category}
            categoryPath={card.categoryPath}
            title={card.title}
            postPath={card.postPath}
          />
        ))}
      </div>
    </section>
  );
};

export default React.memo(Highlights);
