import React from "react";
import BlogPost from "../../../Cards/BlogpostCard";
import { motion } from "framer-motion";
import CommentBox from "../../../Cards/CommentBox";

import Getting_Started from "../../../images/HackVerse/Getting_Started.jpg";
import useImageLoader from "../../../Cards/UseImageLoader";
import linux from "../../../images/HackVerse/linux.jpg";
import tools from "../../../images/HackVerse/tools.png";


const Gettingstartedhackpost = () => {
  const heading = "text-2xl font-bold font-serif font-bold mb-2  text-gray-900";
  const paragraph = "text-base font-normal mb-4  text-gray-800";
  const handleError = (event) => {
    event.target.src = '/path-to-fallback-image.jpg'; // Fallback image in case of an error
  };
  const imageLoaded = useImageLoader(linux)
  return (
    <div className="pt-20">
      <BlogPost
        image={Getting_Started}
        category="HackVerse"
        categoryPath="/HackVerse"
        title="Getting Started with Ethical Hacking: A Beginner's Guide"
      />

      <motion.article
        className="max-w-2xl mx-auto p-4 bg-white rounded-md shadow-sm"
        initial={{ opacity: 1 }}
        animate={{ opacity: 2 }}
        transition={{ duration: 0.5 }}
      >
        {/* Blog Content Start */}

        <h2 className={`${heading} font-extrabold `}>Introduction</h2>
        <p className={paragraph}>
          What is Ethical Hacking? Ethical Hacking is also known as a
          penetration tester or a white hat hacker. It involves finding
          weaknesses or vulnerabilities in computer systems, networks, or web
          applications using tools and tricks, and from your knowledge, and
          fixing them before an attack from black hat hackers or malicious
          hackers. However, white hat hackers do this with the permission of the
          organization.
        </p>

        <h3 className={heading}>
          How Ethical Hackers Differ from Black Hat Hackers
        </h3>
        <p className={paragraph}>
          A Hacker’s who attack organisations , network systems , on websites or
          an a Third party applications with a variety of techniques including
          scanning websites like using ( Nmap - Network Scanning Tool ) to find
          vulnerabilities and security weakness , exploit vulnerabilities ,
          social engineering . They often use Metasploit , Wireshark and much
          more and after finding Weakness sensitive data like username password
          write detailed reports to the orginization outline the vulnerabilities
          discovered and write it which method used to find them .
        </p>
        <p className={paragraph}>
          A Ethical Hacker helps organisations to prevent data breaches, service
          disruption and other cyberattacks But if you find a weakness without
          company permission and use illegal data selling on like Darkweb or
          other unauthorised usage That is Unethical and dangerous for you
        </p>

        <h2 className={heading}>
          Importance of Ethical Hacking in Today’s Digital World
        </h2>
        <p className={paragraph}>
          Digital Security is an essential part known these days in the World .
          Ethical Hackers typically certified ethical Hackers ( C E H ) being
          widely demanded in most of the company . Ethical Hacker’s play many
          roles in organisation . Some Hacker’s identify loopholes and fix
          vulnerabilities before any malicious hacker can exploit it . instead
          of waiting for a cyber attack to happen and then responding .
          protective security needs to do steps in advance . This includes
          regular system monitoring , testing and updating security protocols .
        </p>
        <p className={paragraph}>
          Ethical hacker’s help to prevent all types of digital security like
          Data Breaches , Financial loss and reputation damage
        </p>
        <h3 className="text-md font-semibold">
          Why Ethical Hacking is important ?
        </h3>
        <p className={paragraph}>
          In today , Digital age the number of cyber attacks is rapidly
          increasing day_by_day. Cyber criminals are constantly developing new
          techniques. Today With the rise of remote network , cloud computing ,
          IoT devices they make organisational and individual more vulnerable
          Than ever . At this time they increase the the number of jobs.’
        </p>
        <p className={paragraph}>
          Ethical hacker’s also known as white hat hacker. Ethical hacker and
          Black hat Hacker both use the same technique, ethical hacker identify
          vulnerability assessment and security audit to uncover potential
          threats ,their works help us to prevent data breaches , protect
          sensitive information and ultimately reducing the risk of financial
          loss and reputational damage .
        </p>
        <p className={paragraph}>
          A Ethical contains some permission from the owner of the organisation
          , website owner before Attack on it or find vulnerabilities . A hacker
          must contain a permission letter legal and ethical guidelines
        </p>
        <p className={paragraph}>
          In summary ethical hacker’s is essential in protecting digital assets.
          And maintaining the trust ans security within the organisation.
        </p>

        <h2 className={heading}>Becoming an Ethical Hacker</h2>

        <h3 className={`font-medium font-serif text-md`}>
          I - Basic knowledge Requirement{" "}
        </h3>
        <p className={paragraph}>
          <strong>Understanding of Computer Networks:</strong> Networking is the
          1st step if you want to learn Ethical hacking . A strong grasp of
          computer networking concepts is essential . Include types of protocols
          such as TCP/IP ( Transmission Control Protocol/Internet Protocol ) ,
          UDP , DNS , HTTP/ HTTPS , understand it how data will be transfer from
          one device to another , how devices communicate how protocols operate
          , open ports , close ports , there is a lot of things they help us to
          identify in vulnerability in network configuration and communication .
          If you want to be an expert in computer networking .
        </p>
        <p className={paragraph}>
          <strong> Here’s is some key aspects you need to grasp </strong> TCP /
          IP is the foundational communication protocol of the internet . It
          governs how data is transmitted over the network and how data is
          divided into packets during transformation from one destination to
          another destination . Ethical hackers must know how TCP / IP works and
          how to find vulnerability and how data is handled . Many tools to
          capture data but here is one wireshark is best tool for capturing
          traffic like packets during transformation .This skill is essential
          for how data is transmitted across the network.
        </p>
        <p className={paragraph}>
          DNS ( Domain Name System ) is a just name for a system ( like
          www.example.com ) The computer converts this DNS into IP address (like
          192.198.0.65 _ ) to identify each other on the network . Ethical
          hackers must know how DNS works such as How DNS queries are made ,
          record structure , common vulnerabilities like DNs spoofing cache
          poisoning.
        </p>
        <p className={paragraph}>
          HTTP / HTTPS (Hypertext Transfer Protocol / Secure Hypertext Transfer
          Protocol) Both are used in transmitting web pages over the internet .
          how a message is transmitted and how a webpage browser should respond
          to it . The common difference between HTTP / HTTPS http transfer data
          from one destination to another destination in plain text and https
          transfer data in a secure way with an SSL certificate . Ethical
          hackers must know common weaknesses in both the structure of HTTP , (
          SSL strpping ) and how to test security implementations .
        </p>
        <p className={paragraph}>
          Firewalls, Routers, and Switches understand why firewalls are
          essential for hackers . A firewall works like a filter or as a barrier
          between trusted and untrusted networks based on predefined security
          rules . Router is direct traffic b/t different networks like switches
          connect devices b/t same network . An ethical Hackers will also
          contain knowledge about Firewalls , Routers and Switches e.g how to
          analyze their configuration and find weakness in This .
        </p>
        <p className={paragraph}>
          Wireless . With the rise of cloud computing and wireless networks such
          as wifi . Ethical hackers must need to understand about wireless
          communication protocol how like Wifi (802.11 standards) , security
          measure such as WPA2, WEP, and WPA3 and how test on it week encryption
          , rogue access point , and man in the middle attack (MITM) in wireless
          network.
        </p>

        <h3 className={`font-medium font-serif text-md`}>
          II - Familiarity with Operating Systems, Especially Linux{" "}
        </h3>
        <p className={paragraph}>
          {" "}
          Operating system ( OS ) is the backbone of any computer system .
          Ethical Hackers need a strong familiarity with operating systems such
          as linux because linux is Hacking and penetration base testing OS .
          <p>
            <strong> Linux </strong>
          </p>
            {imageLoaded && (<img
            src={linux}
            alt={"linux OS"}
            className="w-full h-full object-cover"
            onError={handleError}
          />
        ) }
        </p>
        <p className={paragraph}>
          Linux file system is hierarchical structure starting from root ‘ / ‘
          and you can also modify the file system during penetration testing .
          Ethical hacker’s must understand how to manage , modify , change
          things like command ‘chmod’ read, write, execute etc to manipulate
          access rights and maintain security .
        </p>
        <p className={paragraph}>
          Automate any work in linux like if you want it when you restart linux
          OS even on every minute change my location or ip address if you do
          with manually is hard to do it many time but if you do it by automate
          program is easy it and don’t need to update every time just one time
          write script and run lifetime of linux os here you can understand how
          is possible . Bash Shell is the default command-line interpreter for
          many linux distributions . Ethical hackers must know about Bash
          commands and write scripts commonly included ls, cd, grep, find, ps,
          netstat, ifconfig, and chmod. Etc scripting in Bash or other languages
          like python is a good way to automate programs such as scanning
          networks, searching for vulnerabilities, or managing files .
        </p>
        <p className={paragraph}>
          A linux distribution designed it for cybersecurity . Kali Linux comes
          with hundreds of pre-installed tools , programs , forensic analysis
          and is widely used in ethical hacking for conducting comprehensive
          security assessment
        </p>

        <h3 className={`font-medium font-serif text-md`}>
          III - Basic Knowledge of Programming Languages (Optional ) but Good{" "}
        </h3>
        <p className={paragraph}>
          {" "}
          If you want a strong grip in Ethical hacking you need to understand
          the basics of javascript and python . Programming knowledge is a
          fundamental skill for ethical hacker’s . They enable us to understand
          how Software works , identify potential vulnerabilities and create and
          testing tools that are easy to use .
        </p>

        <p className={paragraph}>Why do we learn python and javascript ? .</p>

        <p className={paragraph}>
          Here are the many benefits of learning coding. Sometimes in some cases
          ethical hackers need to reverse engineer to code how it works ,
          identify, develop and exploit a Strong understanding of javascript and
          python is essential for this process.
        </p>
        <p className={paragraph}>
          Ethical hackers sometimes need to create payloads or scripts, in this
          case python is writing payload and javascript is key for web base
          attack .
        </p>

        <p>
          <strong className=" text-gray-900">
            Here is the Basic introduction of javascript ( js ) and python (.py){" "}
          </strong>
        </p>

        <p className={paragraph}>
          Python is a more versatile language and easy to understand and they
          are most used in cybersecurity . Python has an extensive collection of
          libraries and frameworks specifically designed for security tasks :
          Scapy , Requests , Nmap , Pandas and Numpy and much more .
        </p>

        <p className={paragraph}>
          Ethical hackers need to solve complex problems and understand how
          systems work. Problem-solving skills, understanding common attacks,
          and staying updated with best practices are essential. Courses and
          certifications in cybersecurity can provide formal education and
          resources.
        </p>
        <p className={paragraph}>
          Javascript is the modern web app development . They run on virtually
          every browser. Javascript will be used on both sides like frontend
          (Client side ) and Backend ( Server side ) . Ethical hackers need to
          understand the client side scripting
        </p>
        <h2 className={heading}> Skills to Develop :{" "}</h2>
      <p className={paragraph}>
        A Ethical Hacker some time need to solve the complex problem Like They might never hepend on earth . Hacker is terms of both who write  a code and who exploit . Hacking is just mean of to attack on system find vulnerability  and correct it or leak it . Hacker have different end goal 
        </p>
        <p className={paragraph}>
        They need to analyze complex system  understanding and how is different end goals those who progaram many hackers do both Hacking is just  act find clever and counterintuitive solution to a problem . the problem solving techniques is use the rule of computer to bypass security in way’s that’s never happened . 
          </p>
          <p className={paragraph}>
          If you want to  develop a skill  like Problem solving , common attack  . take some courses or certifcate in cybersecurity  or ethical hacking some website provide free resources for forml education  like Cybrary  ,  OpenSecurityTraining.info - Intro to Reverse Engineering  , SANS Cyber Aces Online , khan academy   . 
          </p>
          <p className={paragraph}>
          After complete beginner courses you need to learn just basics programing  like Bash , python  , javascript   you need know about this  like variables , Data type , control structure , function , scripting .  Stay updated  , Apply best practices  implement  in your own project  like securing you own system .
          </p>
  
             
        <h3 className={heading}>Tools and Resources:</h3>
        {imageLoaded && (<img
            src={tools}
            alt={"kali ilnux tools"}
            className="w-full h-full object-cover"
            onError={handleError}
          />
        ) }
   
        <p className={paragraph}>
        Some Popular Tools are like  
          <ul>
            <li>
              {" "}
              <span className="font-bold"> Kali Linux: </span>  Kali linux is Debian Base operating system OS is it destined specifically for penetration testing  . it commes with pre installed tools .Kali linux is commes with wide range resources 
            </li>
            <li>
              {" "}
              <span className="font-bold"> Nmap: </span>is a power tool to discover securing and vulnerability  by send  packets and analyzing the response .
            </li>
            <li>
              {" "}
              <span className="font-bold"> Wireshark: </span> is used in packets capturing , and is widely used in netwrok protocol analyzer .
            </li>
            <li>
              {" "}
              <span className="font-bold"> Metasploit: </span> This tool make easier  to find . exploiting and validating . we can also create and managing payloads for penitration testing
            </li>
            <li>
              {" "}
              <span className="font-bold"> Burp Suite: </span> Burp suite is a comprehensive set of tools for web application testing .
            </li>
          </ul>
          <p className={paragraph} >These some tools are the backbone of ethical hacking work flows .</p>
        </p>

        <h2 className={heading}>Conclusion</h2>
        <p className={paragraph}>
        Most of the people think that  hacking with breaking the laws of and assume that everyone who engage in activities is  a criminal . people who use hacking technique to breaking the law .  The Main purpose of hacking is to secure the orginzaitoin , operating system   by  using new or old technique to  find some weakness  about it . hack is not just use for illeagal purpose  and  every hacker not are the criminal  most of the ues to save it data
        </p>
        {/* Blog Content End */}

        <motion.div
          id="comment-section"
          className="mt-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.6 }}
        >
          <CommentBox />
        </motion.div>
      </motion.article>
    </div>
  );
};

export default Gettingstartedhackpost;
