import React, { useState } from 'react';

const CommentBox = () => {
  const [comment, setComment] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [comments, setComments] = useState([]);
  const [error, setError] = useState('');

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    setError('');

    // Basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment('');
      setName('');
      setEmail('');
      setWebsite('');
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 rounded-md shadow-[inset_0px_4px_10px_rgba(0,0,0,0.2)] bg-white  rounded-lg">
      <h2 className="text-2xl font-semibold mb-4">Leave a Comment</h2>
      <p className="mb-4 text-gray-600">Your email address will not be published. Required fields are marked *</p>
      
      {/* Error Message */}
      {error && (
        <div className="mb-4 text-red-600">
          {error}
        </div>
      )}
      
      <form onSubmit={handleCommentSubmit} className="space-y-6">
        <textarea
          className="w-full p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows="4"
          placeholder="Type here..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          required
        ></textarea>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <input
            type="text"
            className="w-full p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Name*"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            className="w-full p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Email*"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="url"
            className="w-full p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </div>
        <div className="flex items-center mt-4">
          <input type="checkbox" className="mr-2" />
          <span className="text-gray-600">Save my name, email, and website in this browser for the next time I comment.</span>
        </div>
        <button
          type="submit"
          className="mt-4 bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
        >
          Post Comment »
        </button>
      </form>
      
      <div className="mt-8">
        <h3 className="text-xl font-medium text-gray-800 mb-4">Comments</h3>
        {comments.length > 0 ? (
          <ul className="space-y-6">
            {comments.map((c, index) => (
              <li key={index} className="p-6 border rounded-lg bg-gray-50 shadow-sm">
                <div className="mb-2 text-lg text-gray-800">{c.comment}</div>
                <div className="text-sm text-gray-600">
                  <span className="font-semibold">{c.name}</span> | <span>{c.email}</span> | <a href={c.website} className="text-blue-600 hover:underline">{c.website}</a>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No comments yet. Be the first to comment!</p>
        )}
      </div>
    </div>
  );
};

export default CommentBox;
