import React, { useEffect } from "react";

import Factscard from "../../Blogs/Blogcard/Automobilecard/Factscard";
import Millennialscard from "../../Blogs/Blogcard/Automobilecard/Millennialscard";
import Top7answersAutomobilecard from "../../Blogs/Blogcard/Automobilecard/Top7answersAutomobilecard";
import Sidebar from "../../Cards/Sidebar";
import CommingSoon from "../../Cards/Commingsoon";

const Technology = () => {
  useEffect(() => {
    document.title = "Automobile  - PrimeContent";
  }, []);
  return (
    <div className="container pt-20 mx-auto px-4 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Left Section - Blog Section */}
        <div className="col-span-2">
          <h1 className="text-xl  font-semibold text-gray-800 tracking-wide">
            AUTOMOBILE
          </h1>
          <hr />
       <Factscard />
          <Millennialscard />
          <Top7answersAutomobilecard /> 

        </div>

        {/* Right Section - Sidebar */}

        <div className="col-span-1">
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default Technology;
