import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const menuItems = [
    { name: 'Home', path: '/' },
    { name: 'StockMarket', path: '/Stockmarket' },
    { name: 'Technology', path: '/Technology' },
    { name: 'HackVerse' , path: '/HackVerse'},
    { name: 'Politics', path: '/Politics' },
    { name: 'Automobile', path: '/Automobile' },
    { name: 'Health', path: '/Health' },/*
    { name: 'Codebase' , path: '/Codebase'},*/
    { name: 'Inspiration', path: '/Inspiration' },
    { name: 'Interactive Sessions', path: '/Intrectivesessions' },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('scroll', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.removeEventListener('scroll', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }

    return () => {
      document.removeEventListener('scroll', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <header className="bg-white fixed top-0 left-0 right-0 shadow-md z-50">
      <div className="container mx-auto flex items-center justify-between p-4">
        {/* Desktop Navbar */}
        <div className="hidden lg:flex space-x-10 flex-grow justify-center">
          <NavLink to="/" className="text-3xl font-bold font-serif text-gray-900 flex-shrink-0 lg:mr-auto">
            PRIME-CONTENT
          </NavLink>
          <ul className="flex space-x-10">
            {menuItems.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    `text-gray-600 hover:text-black transition-colors duration-300 ${
                      isActive ? 'text-black font-semibold border-b-2 border-black' : ''
                    }`
                  }
                >
                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                    {item.name}
                  </motion.div>
                </NavLink>
              </li>
            ))}
          </ul>
 {/*
          <NavLink
            to="/login"
            className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
          >
            Login
          </NavLink>
*/}
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="text-black focus:outline-none"
            aria-label="Toggle menu"
          >
            <svg
              className={`w-6 h-6 transform transition-transform duration-300 ${
                isOpen ? 'rotate-0' : 'rotate-90'
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        <motion.div
          ref={menuRef}
          className={`lg:hidden fixed top-0 left-0 right-0 bg-white shadow-lg z-50 transform transition-transform duration-300 ${
            isOpen ? 'opacity-100 max-h-screen' : 'opacity-0 max-h-0'
          } overflow-hidden`}
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
          variants={{
            open: { opacity: 1, maxHeight: '100vh' },
            closed: { opacity: 0, maxHeight: 0 },
          }}
          aria-expanded={isOpen}
        >
          <div className="flex items-center justify-between p-4">
            {/* Mobile Menu Items */}
            <button
              onClick={toggleMenu}
              className="text-black focus:outline-none"
              aria-label="Close menu"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              
            </button>
            <NavLink to="/" className="text-2xl font-bold font-serif text-gray-800 lg:hidden flex-shrink-0">
              PRIME-CONTENT
            </NavLink>
          </div>
          
          <ul className="flex flex-col items-start space-y-4 p-4">
            {menuItems.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.path}
                  onClick={() => setIsOpen(false)} // Close menu on link click
                  className={({ isActive }) =>
                    `text-gray-600 hover:text-black transition-colors duration-1000 ${
                      isActive ? 'text-black font-semibold border-b-2 border-black' : ''
                    }`
                  }
                >
                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                    {item.name}
                  </motion.div>
                </NavLink>
              </li>
            ))}
          </ul>
        </motion.div>
        <NavLink to="/" className="text-2xl font-bold font-serif text-gray-800 lg:hidden flex-shrink-0">
              PRIME-CONTENT
            </NavLink>
      </div>
    </header>
  );
};

export default React.memo(Header);
