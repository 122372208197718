import React, { useEffect } from 'react';

const CodeOfConduct = () => {
    useEffect(()=> {
        document.title='Code of Conduct  - PrimeContent'
   } , [])
    return (
        <div className="min-h-screen pt-20 bg-gray-50 text-gray-800 py-20 px-6 lg:px-24">
            <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-lg p-10 lg:p-20">
                <h1 className="text-5xl font-bold mb-10 text-center border-b-2 border-gray-200 pb-5">Code of Conduct</h1>
                <p className="text-lg mb-6 leading-relaxed">
                    Our community values respect and inclusivity. This Code of Conduct outlines how to maintain a positive environment.
                </p>
                <div className="space-y-8">
                    <section className="border-l-4 border-red-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">Respectful Communication</h2>
                        <p className="text-base">
                            We expect all community members to communicate with respect and avoid any offensive language or behavior.
                        </p>
                    </section>
                    <section className="border-l-4 border-red-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">Zero Tolerance for Harassment</h2>
                        <p className="text-base">
                            Harassment, in any form, will not be tolerated. This includes unwelcome comments, threats, or discriminatory actions.
                        </p>
                    </section>
                    <section className="border-l-4 border-red-500 pl-4">
                        <h2 className="text-2xl font-semibold mb-3">Reporting Violations</h2>
                        <p className="text-base">
                            If you experience or witness behavior that violates this Code of Conduct, please report it immediately.
                        </p>
                    </section>
                </div>
                <p className="mt-10 text-center text-sm text-gray-500">
                    For any questions about our Code of Conduct, please contact us.
                </p>
            </div>
        </div>
    );
};

export default CodeOfConduct;
