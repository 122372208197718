import React, { useEffect } from 'react';

import { NavLink } from 'react-router-dom';

import ImageCard from '../Cards/ImageCard';
import ProductCard from '../Cards/ProductCard';
import Highlights from '../Highlight/Highlight';
import NextAuthor from '../Madeable/NextAuthor';
import Automobileuser from '../User/Automobileuser';
import FeaturedBlog from '../User/FeatureBlog';
import Guestpost from '../User/Guestpost';
import Healthuser from '../User/Healthuser';
import Usestockmarket from '../User/Infocarduser';
import Politicsuse from '../User/Polisticsuse';
import RedirectOnRefresh from './RedirectOnRefresh';

//imoprt image path
import inovation from '../../Components/images/Tech/Inovation.jpg'
import Failure from '../../Components/images/Inspiration/Failure.jpg'

const Home = () => {
  useEffect(() => {
    document.title = 'Home - PrimeContent';
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto pt-20 p-4">
      <RedirectOnRefresh/>
      {/* Main Grid for ImageCard and ProductCard */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* ImageCard Example */}
        <div className="col-span-1 md:col-span-2 lg:col-span-2">
          <ImageCard
            backgroundImage={inovation}
            category="Technology"
            categoryPath="/Technology"
            title="AI Innovations: Transforming Every Aspect of Our World"
            date="Aug 18, 2024"
            postPath="/Technology/Aiinovation"
            width="100%"
            height="26rem" // Customize this as needed
          />
        </div>

        {/* ProductCard Example 1 */}
        <div className="col-span-1">
          <ProductCard
            backgroundImage={Failure}
            category="Inspiration"
            categoryPath="/Inspiration"
            title="Why Success Isn't for Everyone : Common Failures"
            date="Aug 19, 2024"
            postPath="/Inspiration/CommonFailure"
            width="100%"       // Card width
            height="auto"      // Card height
            imageWidth="100%"  // Image width inside the card
            imageHeight="16rem" // Image height inside the card
          />
        </div>

            {/*ProductCard Example 2 */}
        <div className="col-span-1 hidden md:block lg:hidden">
          <ProductCard
            backgroundImage="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-latest-news-image-3.jpg"
            category="Health"
            categoryPath="/health"
            title="The Health Industry Is Changing Fast. Here’s How to Keep Pace"
            date="June 28, 2021"
            postPath="/Healtindustry"
            width="100%"       // Card width
            height="auto"      // Card height
            imageWidth="100%"  // Image width inside the card
            imageHeight="16rem" // Image height inside the card
          />
        </div>

      </div>

      <hr className="w-full my-4" />

      <div className="my-6">
        <Highlights />
      </div>

      <hr className="border border-slate-400 mb-3" />


      <div className="flex flex-col my-6">
        <div className="flex justify-between my-5 items-center">
          <h1 className="font-bold text-gray-500 text-3xl">Stock Market</h1>
          <NavLink to="/Stockmarket" className="text-lg text-gray-700 font-semibold">
            View All -{'>'}
          </NavLink>
        </div>
        <Usestockmarket />
      </div>

      <hr className="border border-slate-400 my-4" />

      <div className="flex flex-col my-6">
        <div className="flex justify-between my-5 items-center">
          <h1 className="font-bold text-gray-500 text-3xl">Latest Technology</h1>
          <NavLink to="/Technology" className="text-lg text-gray-700 font-semibold">
            View All -{'>'}
          </NavLink>
        </div>
        <FeaturedBlog />
      </div>
      
      <hr className="border border-slate-400 my-4" />

      <div className="flex flex-col my-6">
        <div className="flex justify-between my-5 items-center">
          <h1 className="font-bold text-gray-600 text-3xl">Politics</h1>
          <NavLink to="/Politics" className="text-lg text-gray-700 font-semibold">
            View All -{'>'}
          </NavLink>
        </div>
        <Politicsuse />
      </div>

      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 p-2">
          <div className="flex flex-col my-6 h-full">
            <div className="flex justify-between my-5 text-gray-600 items-center">
              <h1 className="font-bold text-3xl">Automobile</h1>
              <NavLink to="/Automobile" className="text-lg text-gray-700 font-semibold">
                View All -{'>'}
              </NavLink>
            </div>
            <div className="h-full">
              <Automobileuser />
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 p-2">
          <div className="flex flex-col my-6 h-full">
            <div className="flex justify-between my-5 text-gray-600 items-center">
              <h1 className="font-bold text-3xl">Health</h1>
              <NavLink to="/Health" className="text-lg text-gray-700 font-semibold">
                View All -{'>'}
              </NavLink>
            </div>
            <div className="h-full">
              <Healthuser />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col my-6">
        <div className="flex justify-between my-5 items-center">
          <h1 className="font-bold text-gray-600 text-3xl">Guest</h1>
          <NavLink to="/Technology" className="text-lg text-gray-700 font-semibold">
            View All -{'>'}
          </NavLink>
        </div>
        <Guestpost />
      </div>

      <div className="my-6">
        <NextAuthor />
      </div>
    </div>
  );
};

export default React.memo(Home);
