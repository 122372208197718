import React from 'react';

import Infocard from '../Cards/Infocard';

const Automobileuser = () => {
  return (
    <div className="container mx-auto py-6">
      <div className="flex flex-col m-3 space-y-6">
        <Infocard
          image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-editor-pick-img-6.jpg"
          category="Automobile"
          categoryPath="/Automobile"
          title="Automobile: All the Stats, Facts, and Data You’ll Ever Need to Know"
          titlePath="/AutomobileFacts"
          date="June 28, 2021"
          description="Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet..."
          imgWidth="100%"
          imgHeight="200px" 
        />
        
        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
          <Infocard
            image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-automobile-news-image-1.jpg"
            category="Automobile"
            categoryPath="/Automobile"
            title="How Millennials Are Disrupting Automobile"
            titlePath="/Millennials"
            date="June 28, 2021"
            description="Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet..."
            imgWidth="100%"
            imgHeight="200px" 
          />
          
          <div className="hidden md:block">
            <Infocard
              image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-automobile-news-image-2.jpg"
              category="Automobile"
              categoryPath="/Automobile"
              title="7 Answers to the Most Frequently Asked Questions About Automobile"
              titlePath="/Top7answerAutomobile"
              date="June 28, 2021"
              description="Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet..."
              imgWidth="100%"
              imgHeight="200px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Automobileuser);
