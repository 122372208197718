import React from 'react';

import BlogCard from '../../../Cards/BlogCard';

const TechChangedlifecard = () => {
  return (  
    <div className="my-6"> {/* Added spacing around the card */}
      <BlogCard 
        image="https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-latest-technology-news-image-2.jpg"
        category="Technology"
        categoryPath="/Technology"
        title="Technology Changed My Life. Here’s My Story"
        description="Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet volutpat rutrum sociis quis velit, commodo enim aliquet. Nunc volutpat tortor libero at augue mattis neque, suspendisse aenean praesent sit habitant laoreet felis lorem nibh diam faucibus viverra penatibus donec etiam sem consectetur vestibulum purus […]" 
        postPath="/TechChangedlife"
      />
    </div>
  );
};

export default React.memo(TechChangedlifecard);
