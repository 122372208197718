import './index.css';

import React from 'react';

import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import June2021 from './Components/Archives/June2021';
import May2021 from './Components/Archives/May2021';
import Govtchangecard
  from './Components/Blogs/Blogcard/Politicscard/Govtchangecard';
import Factsposts from './Components/Blogs/Blogpost/Automobilepost/Factspost';
import Millennialspost
  from './Components/Blogs/Blogpost/Automobilepost/Millennialspost';
import Top7answersAutomobilepost
  from './Components/Blogs/Blogpost/Automobilepost/Top7answersAutomobilepost';
import Guest01post from './Components/Blogs/Blogpost/Guestpost/Gest01post';
import Guest02post from './Components/Blogs/Blogpost/Guestpost/Guest02post';
import Common11healthpost
  from './Components/Blogs/Blogpost/Healthpost/Common11healthpost';
import Counterintuitivepost
  from './Components/Blogs/Blogpost/Healthpost/Counterintuitivetips15post';
import Healthindustrypost
  from './Components/Blogs/Blogpost/Healthpost/Healthindustrypost';
import Healthproductpost
  from './Components/Blogs/Blogpost/Healthpost/Healthproductpost';
import CommonFailurepost
  from './Components/Blogs/Blogpost/Inspiration/CommonFailurepost';
import Govtchangepost
  from './Components/Blogs/Blogpost/Politicspost/Govtchangepost';
import Marketeffectpost
  from './Components/Blogs/Blogpost/Politicspost/Marketeffectpost';
import Stocklovepost
  from './Components/Blogs/Blogpost/Politicspost/Stocklovepost';
import ElonTweetpost
  from './Components/Blogs/Blogpost/Stockmarketpost/Elontweetpost';
import Relationshippost
  from './Components/Blogs/Blogpost/Stockmarketpost/Relationshippost';
import Stockeffectpost
  from './Components/Blogs/Blogpost/Stockmarketpost/StockEffectpost';
import LearndStockpost
  from './Components/Blogs/Blogpost/Stockmarketpost/Stocklearndpost';
import Aiinovationpost
  from './Components/Blogs/Blogpost/Techpost/Aiinovationpost';
import TechChangedlifepost
  from './Components/Blogs/Blogpost/Techpost/TechChangedlifepost';
import TechFandamentalpost
  from './Components/Blogs/Blogpost/Techpost/TechFandamentalpost';
import Home from './Components/Home/Home';
import Autombile from './Components/NavPage/Automobile/Autombile';
import Health from './Components/NavPage/Health/Health';
import Inspiration from './Components/NavPage/Inspiration/Inspiration';
import Intrectivesessions
  from './Components/NavPage/Intrectivesessions/Intrectivesessions';
import Politics from './Components/NavPage/Politics/Politics';
import Stockmarket from './Components/NavPage/Stockmarket/Stockmarket';
import Technology from './Components/NavPage/Technology/Technoloy';
import Layout from './Layout';
import reportWebVitals from './reportWebVitals';
import About from './Components/Page/About/About';
import Contact from './Components/Page/Contact/Contact';
import Privacy from './Components/Page/Legal/Privacy';
import CrunchTerms from './Components/Page/Legal/CrunchTerms';
import CodeOfConduct from './Components/Page/Legal/CodeofConduct';
import Terms from './Components/Page/Legal/Terms';
import HackVerse from './Components/NavPage/HackVerse/HackVerse';
import Codebase from './Components/NavPage/Codebase/Codebase';
import Gettingstartedhackpost from './Components/Blogs/Blogpost/HackVersepost/GettingStartedHackingpost';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />}>
      <Route path='/' element={<Home />} />
      <Route path="/Stockmarket" element={<Stockmarket />} />
      {/* Stock Market post here */}
        <Route path='/Elentweet' element={<ElonTweetpost/>} />
        <Route path='/Relationstock' element={<Relationshippost />} />
        <Route path='/LearndStock' element={<LearndStockpost />} />
        <Route path='//Govtchange' element={<Govtchangepost />} />
        <Route path='/Marketeffect' element={<Stockeffectpost/>} />


      <Route path="/Technology" element={<Technology />} />
      {/* Tech Posts */}
        <Route path="/Technology/Aiinovation" element={<Aiinovationpost/>} />
        <Route path='TechFandamental' element={<TechFandamentalpost/>} />
        <Route path='TechChangedlife' element={<TechChangedlifepost/>} />
      
      <Route path="/Politics" element={<Politics />} />
      {/* politics post */}
        <Route path='/Govtchange' element={<Govtchangecard/>} />
        <Route path='/Stockrelation' element={<Relationshippost/>} />
        <Route path='/Marketeffect' element={<Marketeffectpost/>} />
        <Route path='/Stocklove' element={<Stocklovepost/>} />

      <Route path="/Automobile" element={<Autombile />} />
      {/* Automobile post */}
        <Route path='/AutomobileFacts' element={<Factsposts/>} />
        <Route path='/Millennials' element={<Millennialspost/>} />
        <Route path='/Top7answerAutomobile' element={<Top7answersAutomobilepost/>} />

      <Route path="/Health" element={<Health />} />
        {/* Health post */}
        <Route path='/Healtindustry' element={<Healthindustrypost/>} />
        <Route path='/Common11health' element={<Common11healthpost/>} />
        <Route path='/Counterintuitivetips15' element={<Counterintuitivepost/>} />
        <Route path='/Healthproduct'  element={<Healthproductpost/>} />

        {/* HackVerse */}
        < Route path='/HackVerse' element={<HackVerse/>} />
            <Route path='/HacKVerse/Getting_Started_with_Ethical_Hacking' element={<Gettingstartedhackpost/>} />


        {/* Codebase */}
        <Route path='Codebase' element={<Codebase/>} />

        {/* intrectivesessions */}
      <Route path="/Intrectivesessions" element={<Intrectivesessions />} />

      
      <Route path="/Inspiration" element={<Inspiration />} />
        {/* Inspiration */}
        <Route path="Inspiration/CommonFailure" element={<CommonFailurepost/>} />

      {/* Guest post */}
      <Route path='/Guest01post' element={<Guest01post/>} />
      <Route path='/Guest02post' element={<Guest02post/>} />

      {/* Comming Soon */}
      <Route path='/archives/may-2021' element={<May2021/>} />
      <Route path='/archives/june-2021' element={<June2021/>} />

      {/* About us */}
        <Route path='/About'  element={<About/>} />

      {/* Contact */}
        <Route path='/Contact' element={<Contact/>} />

      {/* OUR POLICES */} 
      <Route path='/privacy-policy' element={< Privacy />} />
      <Route path='/extra-crunch-terms' element={< CrunchTerms />} />
      <Route path='/code-of-conduct' element={< CodeOfConduct />} />
      <Route path='/terms-of-service' element={< Terms />} />

    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();