import React from 'react';
import BlogPost from '../../../Cards/BlogpostCard';
import { motion } from 'framer-motion';
import CommentBox from '../../../Cards/CommentBox';

import Failure from '../../../images/Inspiration/Failure.jpg';

const CommonFailurepost = () => {
  const heading = "text-2xl font-semibold mb-2";
  const paragraph = "text-base font-normal mb-4";

  return (
    <div className='pt-20' >
      <BlogPost
        image={Failure}
        category="Inspiration"
        categoryPath="/Inspiration"
        title="Why Success Isn't for Everyone: Common Failures"
      />

      <motion.article
        className="max-w-2xl mx-auto p-4 bg-white rounded-md shadow-sm"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {/* Combined Section: Understanding Failure */}
        <h1 className={`${heading} font-bold font-serif`}>Understanding Failure</h1>
        <p className={paragraph}>
          Failure is something you try but don't succeed in. Like when you set something but don't achieve it, you feel uncomfortable and lose your confidence to do anything. For example, you spend one year learning coding, but you don't develop the skill, so you become disappointed. But when you fail, there’s something to learn. If you spend a year coding, you must have learned something. This thinking moves you forward and makes you feel relaxed and comfortable.
        </p>
        <p className={paragraph}>
          What you thought was a failure turns out to be a success in the end. The Harry Potter book was rejected by 12 publishers before success. Finally, one publisher printed 500 copies, and slowly this book became so famous and sold more than 120 million copies worldwide. Success is just like this—it comes after failure.
        </p>

        {/* Combined Section: Common Reasons for Failure */}
        <h1 className={`${heading} font-bold font-serif`}>Common Reasons for Failure</h1>
        <p className={paragraph}>
          Most people fail because they set goals that are not achievable. This leads them to face failure. Some common problems include:
        </p>
        <p className={paragraph}>
          <strong>Unrealistic Goals:</strong> Many people plan goals that are not achievable for them, so they face failure.
        </p>
        <p className={paragraph}>
          <strong>Lack of Planning:</strong> Without proper planning and strategy, you can't learn correctly, which makes your efforts ineffective.
        </p>
        <p className={paragraph}>
          <strong>Fear of Failure:</strong> Many people have a fear of failure, so they can't achieve their goals and fail.
        </p>
        <p className={paragraph}>
          <strong>Inadequate Resources:</strong> Sometimes, due to a lack of resources, financial issues, or not getting support, people fail.
        </p>
        <p className={paragraph}>
          <strong>Lack of Perseverance:</strong> To achieve success, you need to work consistently, but most people give up after a few days and can't succeed.
        </p>
        <p className={paragraph}>
          <strong>Time Management:</strong> It’s essential to use time wisely. If you don't have a timetable, you'll face failure. If you don't understand your weakness and find mistakes, you'll never be able to improve it.
        </p>

        {/* Combined Section: Turning Failure into Success */}
        <h1 className={`${heading} font-bold font-serif`}>Turning Failure into Success</h1>
        <p className={paragraph}>
          <strong>Trying to Learn:</strong> Whenever you fail, it teaches you something. Learning from failure and moving one step ahead is the way to achieve success.
        </p>
        <p className={paragraph}>
          <strong>Keeping a Positive Mindset:</strong> Always keep a positive mind and remember that after every loss, there’s a win. Always stay confident and try to keep yourself confident.
        </p>
        <p className={paragraph}>
          <strong>Celebrating Small Wins:</strong> Recognizing and celebrating small victories and achievements boosts your confidence.
        </p>
        <p className={paragraph}>
          <strong>Stress Management:</strong> Stress and challenges come in every task, so it’s very important to manage stress.
        </p>
        <p className={paragraph}>
          <strong>Long-Term Vision:</strong> Keep your long-term goals in mind, and whatever challenges come, consider them small errors in front of your long-term goals, like a semicolon mistake in a big project.
        </p>

        {/* Combined Section: Benefits of Failure */}
        <h1 className={`${heading} font-bold font-serif`}>Benefits of Failure</h1>
        <p className={paragraph}>
          Failure pushes you to think creatively and find new solutions when things don’t go as planned. It’s in these challenges that your problem-solving skills truly grow. You learn many new things with motivation.
        </p>
        <p className={paragraph}>
          <strong>Developing Resilience:</strong> Resilience is about getting up and moving forward after failure. The spirit of getting up after failure gives us courage.
        </p>
        <p className={paragraph}>
          <strong>Improvement in Problem-Solving Skills:</strong> Repeatedly getting up after failure and following new strategies makes you a better problem-solver.
        </p>
        <p className={paragraph}>
          <strong>Enhanced Self-Awareness:</strong> It gives us a good chance to understand our strengths and weaknesses.
        </p>
        <p className={paragraph}>
          <strong>Increase in Empathy:</strong> When we go through our experiences and face challenges, our ability to understand others' challenges increases, making many things easier.
        </p>

        {/* Combined Section: Strategies to Turn Failure into Success */}
        <h1 className={`${heading} font-bold font-serif`}>Strategies to Turn Failure into Success</h1>
        <p className={paragraph}>
          <strong>Stay Persistent:</strong> Whenever you face failure, never lose hope. It’s important to keep moving forward despite setbacks. Persistence will help you achieve your goals.
        </p>
        <p className={paragraph}>
          <strong>Seek Feedback:</strong> It’s essential to get feedback to learn from your mistakes and failures. By taking feedback from others, try to improve. This will help you understand yourself better and make you better.
        </p>
        <p className={paragraph}>
          <strong>Celebrate Small Wins:</strong> Recognizing and celebrating small achievements is also important. This will give you motivation and encouragement and keep you focused on your goals.
        </p>
        <p className={paragraph}>
          Every small victory is a step toward the journey of success.
        </p>

        <motion.div
          id="comment-section"
          className="mt-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.6 }}
        >
          <CommentBox />
        </motion.div>
      </motion.article>
    </div>
  );
}

export default CommonFailurepost;
