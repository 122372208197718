import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  return (
    <aside className="p-6  rounded-md shadow-sm">
      {/* Recent Posts */}
      <div className="mb-10">
        <h3 className="text-2xl font-semibold text-gray-800 mb-5">Recent Posts</h3>
        <ul>
          <li className="mb-3">
            <NavLink
              to="/Technology/Aiinovation"
              className="text-gray-700 hover:text-blue-600 hover:underline transition duration-200"
            >
              AI Innovations: Transforming Every Aspect of Our World
            </NavLink>
          </li>
          <li className="mb-3">
            <NavLink
              to="/Inspiration/CommonFailure"
              className="text-gray-700 hover:text-blue-600 hover:underline transition duration-200"
            >
              Why Success Isn't for Everyone: Common Failures
            </NavLink>
          </li>
          <li className="mb-3">
            <NavLink
              to="/HackVerse/Getting_Started_with_Ethical_Hacking"
              className="text-gray-700 hover:text-blue-600 hover:underline transition duration-200"
            >
              Getting Started with Ethical Hacking: A Beginner's Guide
            </NavLink>
          </li>
          {/* Add more posts here */}
        </ul>
      </div>

      {/* Archives */}
      <div className="mb-10">
        <h3 className="text-2xl font-semibold text-gray-800 mb-5">Archives</h3>
        <ul>
          <li className="mb-3">
            <NavLink
              to="/archives/june-2021"
              className="text-blue-600 hover:text-blue-800 hover:underline transition duration-200"
            >
              June 2021
            </NavLink>
          </li>
          <li className="mb-3">
            <NavLink
              to="/archives/may-2021"
              className="text-blue-600 hover:text-blue-800 hover:underline transition duration-200"
            >
              May 2021
            </NavLink>
          </li>
          {/* Add more archives here */}
        </ul>
      </div>

      {/* Categories */}
      <div>
        <h3 className="text-2xl font-semibold text-gray-800 mb-5">Categories</h3>
        <ul>
          <li className="mb-3">
            <NavLink
              to="/Technology"
              className="text-blue-600 hover:text-blue-800 hover:underline transition duration-200"
            >
              Technology
            </NavLink>
          </li>
          <li className="mb-3">
            <NavLink
              to="/Inspiration"
              className="text-blue-600 hover:text-blue-800 hover:underline transition duration-200"
            >
              Inspiration
            </NavLink>
          </li>
          <li className="mb-3">
            <NavLink
              to="/HackVerse"
              className="text-blue-600 hover:text-blue-800 hover:underline transition duration-200"
            >
              HackVerse
            </NavLink>
          </li>
          {/* Add more categories here */}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
