import React, { useEffect } from 'react';

import CommonFailurescard
  from '../../Blogs/Blogcard/Inspirationcard/CommonFailurescard';
import Sidebar from '../../Cards/Sidebar';

const Inspiration = () => {
  useEffect(()=>{
    document.title='Hackerworld - PrimeContent'
  },[])
  return (
    <div className="container pt-20 mx-auto px-4 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">

        {/* Left Section - Blog Section */}
    
        <div className="col-span-2">
        <h1 className="text-xl  font-semibold text-gray-800 pb-3 tracking-wide">
            INSPIRATION
          </h1>
          <hr/>
          <CommonFailurescard/>
        </div>

        {/* Right Section - Sidebar */}
     
         <div className="col-span-1">
            <Sidebar/>
         </div>

      </div>
    </div>
  );
}

export default Inspiration