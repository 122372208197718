import React from 'react';

import Infocard from '../Cards/Infocard';

const Usestockmarket = () => {
  const blogData = [
    {
      image: 'https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-stock-market-news-image-1.jpg',
      category: 'Stock Market',
      categoryPath: '/Stockmarket',
      title: 'What Your Relationship With Stock Market Says About You',
      titlePath: '/Relationstock',
      date: 'June 28, 2021',
      description: 'Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet...',
    },
    {
      image: 'https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-stock-market-news-image-2.jpg',
      category: 'Stock Market',
      categoryPath: '/Stockmarket',
      title: 'How I Learned to Stop Worrying and Love Stock Market',
      titlePath: '/LearndStock',
      date: 'June 28, 2021',
      description: 'Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet...',
    },
    {
      image: 'https://websitedemos.net/business-blog-07/wp-content/uploads/sites/1189/2021/06/business-blog-editor-pick-img-1.jpg',
      category: 'Stock Market',
      categoryPath: '/Stockmarket',
      title: 'Why Stock Market Affects Men and Women Differently',
      titlePath: '/Marketeffect',
      date: 'June 28, 2021',
      description: 'Cursus iaculis etiam in In nullam donec sem sed consequat scelerisque nibh amet, massa egestas risus, gravida vel amet, imperdiet...',
    },
  ];

  return (
    <section className="container mx-auto py-8 px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {blogData.map((blog) => (
          <Infocard
            key={blog.titlePath} // Use a unique key based on titlePath
            image={blog.image}
            category={blog.category}
            categoryPath={blog.categoryPath}
            title={blog.title}
            titlePath={blog.titlePath}
            date={blog.date}
            description={blog.description}
            imgWidth="100%"
            imgHeight="200px" 
          />
        ))}
      </div>
    </section>
  );
};

export default React.memo(Usestockmarket);
