import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import useImageLoader from './UseImageLoader';

const BlogCard = ({ image, title, description, postPath }) => {
  const imageLoaded = useImageLoader(image);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white overflow-hidden mb-8 transition-transform transform hover:scale-105">
      {/* Image */}
      <NavLink to={postPath} aria-label={`Read more about ${title}`}>
        <div className="relative">
          <div className="w-full h-64 bg-gray-200">
            {!imageLoaded && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="spinner border-t-4 border-b-4 border-gray-600 rounded-full w-12 h-12 animate-spin"></div>
              </div>
            )}
            {imageLoaded && (
              <img 
                src={image} 
                alt={title} 
                className="w-full h-full object-cover" 
                loading="lazy" 
              />
            )}
          </div>
        </div>
      </NavLink>

      {/* Content */}
      <div className="p-6">
        {/* Title */}
        <NavLink to={postPath} aria-label={`Read more about ${title}`}>
          <h2 className="text-xl font-semibold text-gray-900 mb-3 hover:text-blue-600">
            {title}
          </h2>
        </NavLink>

        {/* Description */}
        {description && (
          <p className="text-gray-700 mb-4 leading-relaxed">
            {description}
          </p>
        )}

        {/* Read More Link */}
        <NavLink to={postPath} className="text-blue-600 font-medium hover:underline" aria-label={`Read the full article: ${title}`}>
          Read more...
        </NavLink>
      </div>
    </div>
  );
};

BlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  postPath: PropTypes.string.isRequired,
};

export default React.memo(BlogCard);
