import React from 'react';

import { NavLink } from 'react-router-dom';

const Footer = () => {
  const CurrentYear = () => {
    const year = new Date().getFullYear();
    return <span>{year}</span>
  }
  return (
    <footer className="border-t pt-3">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">
          {/* About Column */}
          <div>
            <h3 className="text-lg font-bold font-serif mb-4 text-gray-800">About</h3>
            <p className="text-gray-600">
            Discover new ideas and keep learning with our latest articles. Dive in and find fresh insights on topics you care about!
            </p>
          </div>

          {/* Company Column */}
          <div>
            <h3 className="text-lg font-bold font-serif mb-4 text-gray-800">Company</h3>
            <ul className="space-y-2">
              <FooterLink to="/About" label="About Us" />
              <FooterLink to="/contact" label="Contact Us" />
            </ul>
          </div>

          {/* Legal Column */}
          <div>
            <h3 className="text-lg font-bold font-serif mb-4 text-gray-800">Legal</h3>
            <ul className="space-y-2">
              <FooterLink to="/privacy-policy" label="Privacy Policy" />
              <FooterLink to="/terms-of-service" label="Terms of Service" />
              <FooterLink to="/extra-crunch-terms" label="Extra Crunch Terms" />
              <FooterLink to="/code-of-conduct" label="Code of Conduct" />
            </ul>
          </div>

          {/* Follow Us Column 
          <div>
            <h3 className="text-lg font-bold font-serif mb-4 text-gray-800">Follow Us</h3>
            <ul className="space-y-2">
              <SocialLink href="https://facebook.com" label="Facebook" />
              <SocialLink href="https://youtube.com" label="YouTube" />
              <SocialLink href="https://twitter.com" label="Twitter" />
              <SocialLink href="https://instagram.com" label="Instagram" />
            </ul>
          </div>
          */}
        </div>

        <div className="border-t border-gray-300 mt-8 pt-4 flex flex-col md:flex-row justify-between items-center text-gray-600 text-sm">
          <p>&copy; <CurrentYear/>  PrimeContent. All rights reserved. | Powered by NextGen with JUNAID</p>
        </div>
      </div>
    </footer>
  );
};

const FooterLink = ({ to, label }) => (
  <li>
    <NavLink
      to={to}
      className="text-gray-600 hover:text-blue-600 transition-colors duration-300"
    >
      {label}
    </NavLink>
  </li>
);



export default React.memo(Footer);
