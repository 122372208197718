import React, { useEffect } from 'react';

const May2021 = () => {
  useEffect(()=>{
    document.title='Hackerworld - PrimeContent'
  },[])
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">

        {/* Left Section - Blog Section */}
    
        <div className="col-span-2">
        <div className="flex items-center justify-center min-h-screen bg-white">
      <div className="text-center p-8 max-w-lg bg-white shadow-lg rounded-lg">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Coming Soon
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          We're working hard to give you a great experience! Stay tuned.
        </p>
        <div className="relative h-1 bg-gray-200 rounded overflow-hidden">
          <div className="absolute inset-0 bg-indigo-500" style={{ width: '40%' }}></div>
        </div>
        <p className="text-sm text-gray-500 mt-2">
          40% Completed
        </p>
      </div>
    </div>
        </div>

      </div>
    </div>
  );
}

export default May2021;